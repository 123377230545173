<template>
  <v-form
      v-model="valid"
      @submit.prevent="send"
  >
    <v-card-title class="subtitle-1 text--secondary font-weight-regular justify-space-between">
      <span>Vous avez oublié votre mot de passe?</span>
      <v-spacer/>
      <v-icon color="grey lighten-2">{{$icons.userCircle}}</v-icon>
    </v-card-title>

    <v-card-text>
      <div>Sasissez votre email, vous recevrez un code qui vous permettra de renseigner un nouveau mot de passe.</div>
      <v-text-field
          ref="email-field"
          v-model="email"
          label="Email"
          type="email"
          :rules="emailRules"
          :autocomplete="autocomplete"
          @focus="focusIn"
          @blur="focusOut"
      ></v-text-field>
      <v-text-field
          v-if="codeSent"
          ref="code-field"
          v-model="code"
          label="Code reçu par mail"
          :autocomplete="autocomplete"
          @focus="focusIn"
          @blur="focusOut"
      ></v-text-field>
      <v-text-field
          v-if="code !=='' "
          ref="pwd-field"
          v-model="password"
          label="Nouveau mot de passe"
          :rules="passwordRules"
          :append-icon="showpwd ? $icons.eye : $icons.eyeOff"
          @click:append="showpwd = !showpwd"
          :type="showpwd ? 'text' : 'password'"
          :autocomplete="autocomplete"
          @focus="focusIn"
          @blur="focusOut"
      ></v-text-field>

      <v-alert v-if="error"
               transition="fade-transition"
               dismissible
               :icon="false"
               outlined
               tile
               type="error">
        {{error}}
      </v-alert>
      <v-alert v-if="message"
               transition="fade-transition"
               dismissible
               :icon="false"
               outlined
               tile
               type="success">
        {{message}}
      </v-alert>
    </v-card-text>

    <v-divider></v-divider>
    <v-card-actions>
      <slot name="action-left"></slot>
      <v-spacer></v-spacer>
      <v-btn
          class="my-2"
          :disabled="!valid"
          :loading="wait"
          type="submit"
          color="primary">
        Envoyer
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import UserModel from "@/Models/UserModel";

export default {
  name: "lost-pwd-form",
  data(){
    return{
      /**
       * true si le formulaire peut être envoyé
       */
      valid: false,
      email: '',
      code:'',
      codeSent:false,
      password:'',
      showPw:false,
      persistentHint: false,
      wait: false,
      error: false,
      message: false,
      showpwd:false,
      autocomplete:'new-password',
    }
  },
  mounted() {
    //this.$refs["email-field"].focus();
    //this.$refs["pwd-field"].focus();
  },
  computed:{
    passwordRules(){
      return UserModel.passwordRules();
    },
    emailRules(){
      return UserModel.emailRules();
    }
  },
  methods: {
    focusIn(){
      this.autocomplete=""
    },
    focusOut(){
      this.autocomplete="new-password"
    },
    send() {
      let me = this;
      me.wait = true;
      me.error = false;
      me.message = false;
      if(!this.code){
        this.$api.lostPwd(
            this.email,
            function (data) {
              console.log("success",data)
              me.message = data.messages[0].message;
              me.wait = false;
              me.codeSent=true;
            },
            function (data) {
              console.log("error",data.errors)
              me.error = data.errors[0].message;
              me.wait = false;
            }
        )
      }else{
        this.$api.changePwd(
            this.email,
            this.code,
            this.password,
            function (data) {
              console.log("success",data)
              me.message = data.messages[0].message;
              me.wait = false;
              me.codeSent=false;
              me.password="";
              me.code="";
              setTimeout(function(){
                me.message="";
              },4000)
            },
            function (data) {
              console.log("error",data.errors)
              me.error = data.errors[0].message;
              me.wait = false;
            }
        )
      }

    }
  }
}
</script>

<style scoped>

</style>