<template>
  <v-container fluid>
    <div v-if="!$db.userAdmin || !$db.userAdmin.canEditRedirections">
      <div class="d-flex justify-center align-center" style="min-height: 80vh">
        <div class="mx-auto">
          <v-alert
              v-if="$db.userAdmin && !$db.userAdmin.canEditRedirections"
              class="my-5"
              color="error"
              elevation="5"
              dark dense
          >
            Vous n'avez pas l'autorisation de gérer les redirections
          </v-alert>
          <login-box
              class="flex-shrink-0"
              @login="$utils.navigation.reload()"
          />
        </div>
      </div>
    </div>
    <div v-else>

      <v-row class="py-5">
        <v-col cols="3">
          <v-text-field
              v-model="search"
              rounded filled
              :append-icon="$icons.search"
              label="Rechercher"
              clearable
              single-line
              hide-details
          />
        </v-col>



        <v-col cols="3" class=" align-center d-flex">
          <span class="mr-5 primary--text subtitle-2">{{groupActionLabel}}</span>
          <div v-if="selected.length>0">
            <v-btn icon small @click="trashSelection" class="mr-2">
              <v-icon>{{$icons.trash}}</v-icon>
            </v-btn>
            <v-btn icon small @click="csvSelection">
              <v-icon>{{$icons.fileExcel}}</v-icon>
            </v-btn>
          </div>
        </v-col>
        <v-spacer/>
        <v-col cols="3" class=" align-center d-flex justify-end" >
          <v-btn color="primary" dark @click="open(null)" >Ajouter</v-btn>
          <v-dialog
              v-model="editOpen"
              persistent
              max-width="900px"
          >
            <v-form @submit.prevent="save">
              <v-card>
                <v-card-text>
                  <v-container>

                    <v-row>
                      <v-col
                          cols="12"
                      >
                        <v-text-field
                            v-model="edited.name"
                            autofocus
                            label="Url"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                      >
                        <v-text-field
                            v-model="edited.redirect"
                            label="Redirection"
                        />
                        <record-field
                            :field="searchRecord"
                            :record-types="[]"
                            label="Rechercher"/>
                      </v-col>
                      <v-col
                          cols="12"
                      >
                        <v-switch
                            v-model="edited.force"
                            label="Forcer la redirection"
                        ></v-switch>
                      </v-col>
                    </v-row>

                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close" >
                    Annuler
                  </v-btn>
                  <v-btn color="blue darken-1" text type="submit">
                    Enregistrer
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
        </v-col>

      </v-row>

      <v-data-table
          show-select checkbox-color="primary"
          v-model="selected"
          :search="search"
          :items="lines"
          :headers="headers"
          disable-pagination
          hide-default-footer
          @click:row="clickrow"
      >
        <template v-slot:item.force="{ item }">
          <v-icon v-if="item.force" color="primary">{{$icons.check}}</v-icon>
        </template>

        <template v-slot:item.ville="{ item }">
          {{item.ville}} <br>
          {{item.cp}} - {{item.pays}}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn icon small @click.stop="trash(item)">
            <v-icon>{{$icons.trash}}</v-icon>
          </v-btn>
        </template>

      </v-data-table>
    </div>
  </v-container>



</template>

<script>


import DateField from "@/ee09/fields/DateField";
import LoginBox from "@/ee09/login/login-box";
import RecordFieldModel from "@/ee09/fields/RecordField";
import RecordField from "@/ee09/db-ui/fields/record-field";

export default {
  name: "redirections",
  components: {RecordField, LoginBox},
  data(){
    return{
      /**
       *@type {RecordField}
       **/
      searchRecord:null,
      type:"redirecturl",
      records:[],
      selected:[],
      search:"",
      editOpen:false,
      edited:{
        name:"",
        redirect:""
      }
    }
  },
  mounted(){
    this.$layout.currentPage.uid=null;
    this.searchRecord=new RecordFieldModel();
    if(this.$db.userAdmin){
      this.load();
    }

  },
  computed:{
    /**
     *@type {PageModel}
     **/
    searchRecordResult(){
      if(this.searchRecord){
        return this.searchRecord.record
      }
      return null;
    },
    lines(){
      let r=this.records.filter(recrd=>recrd.type==="redirecturl");
      return r;
    },
    groupActionLabel(){
      let r="Aucune sélection";
      if(this.selected.length===1){
        r="Pour cette ligne";
      }else if(this.selected.length>1){
        r="Pour ces "+this.selected.length+" lignes"
      }
      return r;
    },
    headers(){
      let h=[];
      h.push(
          {
            text: 'Url',
            value: 'name'
          }
      );
      h.push(
        {
          text: 'Redirection',
          value: 'redirect',
        }
      );
      h.push(
          {
            text: 'Forcer la redirection',
            value: 'force',
          }
      );
      h.push(
          {
            text: '...',
            value: 'actions',
            sortable:false,
            align:"end"
          }
      );
      return h;
    },
    selectedLinesPlural(){
      if(this.selected.length===1){
        return "cette ligne";
      }
      if(this.selected.length>1){
        return "ces "+this.selected.length+" lignes";
      }
      return "aucune ligne"
    }
  },
  watch:{
    searchRecordResult(){
      let me=this;
      if(this.searchRecordResult){
        this.edited.redirect=this.searchRecordResult.pageUrl.slug;
        setTimeout(function(){
          me.searchRecord.record=null;
        },200)

      }
    }
  },
  methods: {
    getNew(){
      return {
        type:"redirecturl",
        isNew:true,
        name:"",
        force:false,
        redirect:""
      }
    },
    clickrow(item){
      this.open(item)
    },
    open(record){
      console.log("open",record)
      if(record){
        this.edited=record;
      }else{
        this.edited=this.getNew();
      }
      this.editOpen=true;
    },
    close(){
      this.editOpen=false;
    },
    save(){
      console.log("save",this.edited)
      let me=this;

      window.$api.setStoreRecord(this.edited,function(data){
        if(data.success){
          me.close();
          me.load();
        }else{
          console.error("setStoreRecord")
          console.error(data.errors);
        }
      })

    },
    load(){
      let me=this;
      this.$api.getListRecords(["redirecturl"],function(data){
        console.log(data)
        me.records=data.body.records;
        for(let r of me.records){
          r.date=new DateField()
          r.date.isoString=r.date_created.isoString;
        }
      })
    },
    trash(line,askConfirm=true){
      if(askConfirm){
        if(confirm("Êtes-vous sur de vouloir supprimer cette ligne définitivement ?")){
          this.trash(line,false)
        }
      }else{
        console.log("trash",line)
        let me=this;
        window.$api.setTrashRecord(line,function(){
          me.load();
        })
      }

    },
    trashSelection(){
      if(confirm("Êtes-vous sur de vouloir supprimer "+this.selectedLinesPlural+" définitivement ?")){
        for(let line of this.selected){
          this.trash(line,false);
        }
      }

    },
    csvSelection(){
      let lines=[];
      for(let rec of this.selected){
        lines.push(this.csvObject(rec))
      }
      console.log(lines);
      this.$utils.navigation.openBlankWithPost(
          this.$api.xls(),lines
      )

    },
    /**
     *
     * @param {DbRecord} line
     */
    csvObject(line){
      let r={};
      r.Date=line.date.format('dd-MM-yyyy')
      r.Url=line.name;
      r.Redirect=line.redirect;
      return JSON.parse(JSON.stringify(r));
    }


  }
}
</script>
