<template>
  <v-card
      elevation="15"
      class="login-box"
      width="500"
  >
    <v-tabs-items v-model="tab">
      <v-tab-item :key="0">
        <login-form @login="$emit('login')">
          <template v-slot:action-left>
            <v-btn
                @click="tab=1"
                small text color="grey">
              Mot de passe oublié ?
            </v-btn>
          </template>
        </login-form>
      </v-tab-item>
      <v-tab-item :key="1">
        <lost-pwd-form>
          <template v-slot:action-left>
            <v-btn
                small text color="grey"
                @click="tab=0">
              connexion
            </v-btn>
          </template>
        </lost-pwd-form>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>

import LostPwdForm from "@/ee09/login/lost-pwd-form";
import LoginForm from "@/ee09/login/login-form";

export default {
  name: "login-box",
  components: {LoginForm, LostPwdForm},
  data(){
    return{
      tab:0
    }
  }
}
</script>

<style lang="less">
.login-box{
  .v-text-field,.v-textarea{
    border-radius: 0;
    //font-family: var(--font-family-sans);
    /* Change the white to any color */
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    textarea:-webkit-autofill:active
    {
      -webkit-text-fill-color: black !important;
      -webkit-box-shadow: 0 0 0 30px #fff inset !important;
    }
  }
}
</style>