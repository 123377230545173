<template>
  <v-form
      autofocus
      v-model="valid"
      @submit.prevent="login">
    <v-card-title class="subtitle-1 text--secondary font-weight-regular justify-space-between">
      <span>Veuillez vous connecter</span>
      <v-spacer/>
      <v-icon color="grey lighten-2">{{$icons.userCircle}}</v-icon>
    </v-card-title>
    <v-card-text>
      <v-text-field
          ref="email-field"
          v-model="email"
          label="Email"
          type="email"
          :rules="emailRules"
          :autocomplete="autocomplete"
          @focus="focusIn"
          @blur="focusOut"
      ></v-text-field>
      <v-text-field
          ref="pwd-field"
          v-model="password"
          label="Mot de passe"
          :rules="passwordRules"
          :append-icon="showpwd ? $icons.eye : $icons.eyeOff"
          @click:append="showpwd = !showpwd"
          :type="showpwd ? 'text' : 'password'"
          :autocomplete="autocomplete"
          @focus="focusIn"
          @blur="focusOut"
      ></v-text-field>

      <v-alert v-if="error"
               transition="fade-transition"
               dismissible
               :icon="false"
               outlined
               tile
               type="error">
        {{error}}
      </v-alert>
    </v-card-text>

    <v-divider></v-divider>
    <v-card-actions>
      <slot name="action-left"></slot>
      <v-spacer></v-spacer>
      <v-btn
          class="my-2"
          :disabled="!valid"
          :loading="wait"
          type="submit"
          color="primary">
        <v-icon left>{{$icons.lock}}</v-icon>
        connexion
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import UserModel from "@/Models/UserModel";

export default {
  name: "login-form",
  data(){
    return{
      /**
       * true si le formulaire peut être envoyé
       */
      valid: false,
      email: '',
      password:'',
      showPw:false,
      persistentHint: false,
      wait: false,
      error: false,
      showpwd:false,
      //autocomplete:'new-password',
      autocomplete:'',
    }
  },
  mounted() {
    //this.$refs["email-field"].focus();
    //this.$refs["pwd-field"].focus();
  },
  computed:{
    passwordRules(){
      return UserModel.passwordRules();
    },
    emailRules(){
      return UserModel.emailRules();
    }
  },
  methods: {
    focusIn(){
      this.autocomplete=""
    },
    focusOut(){
      //this.autocomplete="new-password"
      this.autocomplete=""
    },
    login() {
      let me = this;
      me.wait = true;
      me.error = false;
      this.$api.login(
          this.email,
          this.password,
          function (user) {
            console.log("success",user)
            me.wait = false;
            me.$emit("login")
          },
          function (data) {
            console.log("error",data.errors)
            me.error = data.errors[0].message;
            me.wait = false;
          }
      )
    }
  }
}
</script>

<style scoped>

</style>